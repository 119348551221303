export const stageMapping: { [key: string]: string } = {
   Quote: 'Quote',
   Pending: 'Open Orders',
   'Open Orders': 'Open Orders',
   Blocked: 'Open Orders',
   Drawings: 'Open Orders',
   'In Production': 'In Production',
   Shipping: 'Shipping',
   'Invoiced and delivered': 'Delivered',
   'Invoiced and Delivered': 'Delivered',
   Delivered: 'Delivered',
   Invoiced: 'Delivered',
   Canceled: '',
   'Expired Quotes': '',
   Unknown: '',
};
