import { ComplainProvider } from '../../contexts/ComplainContext';
import ComplainFlow from '../../components/Complains/ComplainFlow/ComplainFlow';
import Content from '../../components/Content/Content';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';
import { useTranslation } from 'react-i18next';

export default function ComplainPage() {
   let { id } = useParams();
   const { t } = useTranslation('orders_complain');

   const { deals, loadingDeals } = useContext(UserContext);

   const order = deals?.orderDeals?.find((order) => order.id.toString() === id);

   return (
      <ComplainProvider>
         <Content>
            {(loadingDeals || order?.canClaimBeCreatedForThisOrder) && (
               <ComplainFlow />
            )}

            {!loadingDeals && !order?.canClaimBeCreatedForThisOrder && (
               <h1>{t('complain_unavailable')}</h1>
            )}
         </Content>
      </ComplainProvider>
   );
}
