import { useTranslation } from 'react-i18next';
import styles from './ComplainSelectedItems.module.scss';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';
import { ComplainContext } from '../../../contexts/ComplainContext';
import { getOrderLineItemsSelectedIds } from '../../../utils/getOrderLineItemsSelectedIds';
import Card from '../../Card/Card';
import { ReactComponent as CheckmarkIcon } from '../../../assets/icons/checkmark-rounded.svg';

interface Props {
   current: number;
   currentIndex: number;
}

export default function ComplainSelectedItems({
   current,
   currentIndex,
}: Props) {
   const { t } = useTranslation('complain_steps');

   let { id } = useParams();

   const { lineItems } = useContext(UserContext);
   const { selectedOrderLines } = useContext(ComplainContext);

   const orderId = id?.toString();

   if (!orderId || !lineItems) return null;

   const orderLineItems = getOrderLineItemsSelectedIds(
      lineItems[orderId],
      selectedOrderLines
   );

   return (
      <div>
         <Card title={t('selected_elements')}>
            <div className={styles.elements}>
               {orderLineItems.map((orderLineItem, index) => (
                  <div
                     key={orderLineItem.id}
                     className={`${styles.element} ${current == orderLineItem.id ? styles.active : ''}
                  ${currentIndex > index ? styles.completed : ''}
                  `}
                  >
                     <CheckmarkIcon />
                     {orderLineItem.name}
                  </div>
               ))}
            </div>
         </Card>
      </div>
   );
}
