import { useTranslation } from 'react-i18next';
import { UserContext } from '../../contexts/UserContext';
import { useContext, useEffect, useRef } from 'react';
import styles from './ClaimTicketPage.module.scss';
import { useParams } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import Content from '../../components/Content/Content';
import Button from '../../components/Button/Button';
import { fetchApi } from '../../utils/fetchApi';
import ClaimOverview from '../../components/ClaimOverview/ClaimOverview';
import BackButton from '../../components/BackButton/BackButton';
import { claimStatusMap } from '../../utils/claimStatusMap';

export default function ClaimTicketPage() {
   const { t } = useTranslation('tickets');

   let { id } = useParams();

   const imagesRequested = useRef(false);
   const linesRequested = useRef(false);

   const {
      tickets,
      loadingTickets,
      fetchClaimTicketLineItems,
      fetchClaimTicketImages,
      fetchTickets,
      config,
   } = useContext(UserContext);

   const claim = tickets?.claimTickets.find(
      (ticket) => ticket.id.toString() === id
   );

   useEffect(() => {
      if (!claim?.lineItems && id && !linesRequested.current) {
         linesRequested.current = true;
         fetchClaimTicketLineItems(id);
      }
      if (!claim?.images && id && !imagesRequested.current) {
         imagesRequested.current = true;
         fetchClaimTicketImages(id);
      }
   }, [id]);

   const closeTicket = async () => {
      if (confirm(t('close_ticket_confirmation'))) {
         await fetchApi(`/api/logged-in-user/claim-ticket/${id}/close`, {
            method: 'POST',
         });
         fetchTickets(true);
      }
   };

   if (!id) return null;

   const isClosed =
      claim?.status !== 'technician-case-closed' &&
      claim?.status !== 'support-closed';

   return (
      <Content>
         <BackButton to='/claims'>{t('claim_overview')}</BackButton>

         <h1>
            {t('ticket_number')}: {id}
         </h1>

         <div className={styles.header}>
            {claim?.status && (
               <div className={styles.status}>
                  <div>{t('status')}</div>
                  <strong>{` ${t(`status_${claimStatusMap[claim.status]}`)}`}</strong>
               </div>
            )}

            <div className={styles.actions}>
               {isClosed && (
                  <>
                     <Button variant='ghost' onClick={closeTicket}>
                        {t('close_ticket')}
                     </Button>

                     {config?.email && (
                        <Button
                           target='_blank'
                           to={`mailto:${config.email}`}
                           variant='ghost'
                        >
                           {t('edit_ticket')}
                        </Button>
                     )}
                  </>
               )}

               <Button to='/messages'>{t('see_contact_history')}</Button>
            </div>
         </div>

         {loadingTickets ? (
            <Loading />
         ) : claim ? (
            <div>
               <div className={styles.reasons}>
                  {claim.lineItems?.map((item) => (
                     <ClaimOverview
                        key={item.idOfOrderLineItem}
                        name={item.orderLineItem.name}
                        reasons={item.claimErrorReasonValues}
                        description={item.elaboratedReason}
                        images={item.images?.map((x) => x.tempUrl || '')}
                     />
                  ))}
               </div>
            </div>
         ) : (
            <div>{t('order_not_found')}</div>
         )}
      </Content>
   );
}
