import { useTranslation } from 'react-i18next';
import styles from './ClaimTicketLine.module.scss';
import { formatDate } from '../../utils/formatDate';
import { ClaimTicket } from '../../types/Tickets';
import CardLine from '../CardLine/CardLine';
import { claimStatusMap } from '../../utils/claimStatusMap';

interface Props {
   ticket: ClaimTicket;
}

export default function ClaimTicketLine({ ticket }: Props) {
   const { t } = useTranslation('tickets');

   return (
      <CardLine to={`/claims/${ticket.id}`}>
         <div className={styles.header}>
            <strong>
               {t('claim')}: {ticket.id}
            </strong>

            <strong className={styles.date}>
               {formatDate(ticket.timeTicketCreated)}
            </strong>
         </div>

         <div className={styles.content}>
            <div>{t(`status_${claimStatusMap[ticket.status]}`)}</div>
            {ticket.orderNumberOfAssociatedDeal && (
               <div>
                  {t('order_number')} {ticket.orderNumberOfAssociatedDeal}
               </div>
            )}
         </div>
      </CardLine>
   );
}
