import { useTranslation } from 'react-i18next';
import styles from './ClaimOverview.module.scss';
import Card from '../Card/Card';
import CardLine from '../CardLine/CardLine';
import ComplainReasonLabel from '../Complains/ComplainReasonLabel/ComplainReasonLabel';

interface Props {
   name: string;
   reasons: string[];
   description: string;
   images?: string[];
}

export default function ClaimOverview({
   name,
   reasons,
   description,
   images,
}: Props) {
   const { t } = useTranslation('complain_steps');

   return (
      <Card title={name}>
         <CardLine>
            <div className={styles.reason}>
               <h3>{t('reason_label')}:</h3>
               <ul>
                  {reasons.map((reason, index) => (
                     <li key={index}>
                        <ComplainReasonLabel reason={reason} />
                     </li>
                  ))}
               </ul>
               <h3>{t('description_label')}:</h3>
               <p className={styles.description}>{description}</p>
               {images && images.length > 0 && (
                  <>
                     <h3>{t('uploaded_images')}:</h3>
                     <div className={styles.preview}>
                        {images.map((file, index) => (
                           <div className={styles.image} key={index}>
                              <img src={file} />
                           </div>
                        ))}
                     </div>
                  </>
               )}
            </div>
         </CardLine>
      </Card>
   );
}
