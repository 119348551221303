import { OrderStage, OrderStageHistoryItem } from '../../types/Deal';
import { formatDate } from '../../utils/formatDate';
import { useTranslation } from 'react-i18next';
import Progress from '../Progress/Progress';
import { stageMapping } from '../../utils/stageMapping';

interface Props {
   stages: OrderStageHistoryItem[];
}

export default function OrderStages({ stages }: Props) {
   const { t } = useTranslation('orders');

   let standardProcess: string[] = [
      'Open Orders',
      'In Production',
      'Shipping',
      'Delivered',
   ];

   const lastStage = stages[stages.length - 1];

   if (lastStage.stage === 'Quote') {
      standardProcess = ['Quote', ...standardProcess];
   }

   let activeIndex = 0;

   const steps = standardProcess.map((stage, index) => {
      let description = '';
      let stageTime = stages.find(
         (stageItem) => stageMapping[stageItem.stage] === stage
      )?.time;
      if (stageTime) {
         description = formatDate(stageTime);

         if (index > activeIndex) {
            activeIndex = index;
         }
      }

      return {
         title: t(`status_${stage}`),
         description,
      };
   });

   return <Progress steps={steps} activeIndex={activeIndex} />;
}
