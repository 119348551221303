import { ReactComponent as ExitIcon } from '../../assets/icons/exit.svg';
import { Notice } from '../../types/User';
import Button from '../Button/Button';
import styles from './Notice.module.scss';
import { Link } from 'react-router-dom';

interface Props {
   notice: Notice;
}

export default function BannerNotice({ notice }: Props) {
   return (
      <div className={styles.notice}>
         <div className={styles.noticeText}>{notice.title}</div>
         {notice.link && notice.linkText && (
            <Button to={notice.link} target='_blank' small>
               {notice.linkText}
               <ExitIcon />
            </Button>
         )}
      </div>
   );
}
