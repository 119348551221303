import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../contexts/UserContext';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/arrow-right.svg';
import OrderLineItems from '../../../components/OrderLineItems/OrderLineItems';
import styles from './ComplainElementsStep.module.scss';
import { ComplainContext } from '../../../contexts/ComplainContext';
import { getOrderLineItemsSelectedIds } from '../../../utils/getOrderLineItemsSelectedIds';
import Loading from '../../Loading/Loading';

export default function ComplainElementsStep() {
   const { t } = useTranslation('complain_steps');
   let { id } = useParams();

   const {
      deals,
      loadingDeals,
      fetchOrderLineItems,
      loadingLineItems,
      lineItems,
   } = useContext(UserContext);

   const { setStep, selectedOrderLines } = useContext(ComplainContext);

   useEffect(() => {
      if (!id) return;

      fetchOrderLineItems(id);
   }, [id]);

   const orderId = id;

   const order = deals?.orderDeals?.find(
      (order) => order.id.toString() === orderId
   );

   if (!orderId || !lineItems) return <Loading />;

   const orderLineItems = getOrderLineItemsSelectedIds(lineItems[orderId]);

   return (
      <div>
         {loadingDeals || loadingLineItems ? (
            <Loading />
         ) : order ? (
            <div>
               {orderId && lineItems && lineItems[orderId] && (
                  <>
                     <OrderLineItems
                        currency={order.orderDetails.currencyCode}
                        lineItems={orderLineItems}
                        selectable
                     />
                     <div className={styles.actions}>
                        <Button to='/orders/complain' variant='ghost'>
                           <ArrowLeftIcon />
                           {t('go_back')}
                        </Button>
                        {selectedOrderLines.length > 0 && (
                           <Button onClick={() => setStep(2)}>
                              {t('go_to_description')}
                              <ArrowRightIcon />
                           </Button>
                        )}
                     </div>
                  </>
               )}
            </div>
         ) : (
            <div>{t('order_not_found')}</div>
         )}
      </div>
   );
}
