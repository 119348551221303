import { Link, NavLink } from 'react-router-dom';

import styles from './Sidebar.module.scss';
import { UserContext } from '../../contexts/UserContext';
import { useContext, useEffect, useState } from 'react';
import { ReactComponent as HomeIcon } from '../../assets/icons/home.svg';
import { ReactComponent as ProposalIcon } from '../../assets/icons/proposal.svg';
import { ReactComponent as OrderIcon } from '../../assets/icons/order.svg';
import { ReactComponent as ChatsIcon } from '../../assets/icons/chats.svg';
import { ReactComponent as ClaimIcon } from '../../assets/icons/claim.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { ReactComponent as BurgerIcon } from '../../assets/icons/burger.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as SupportIcon } from '../../assets/icons/support.svg';
import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';
import { useTranslation } from 'react-i18next';

export default function Sidebar() {
   const { config, deals, tickets, conversations } = useContext(UserContext);
   const [open, setOpen] = useState(false);

   const { t } = useTranslation('sidebar');

   useEffect(() => {
      setOpen(false);
   }, [window.location.pathname]);

   if (!config) return null;

   const links = [
      { to: '/', label: t('home'), icon: <HomeIcon /> },
      {
         to: '/proposals',
         label: t('proposals'),
         icon: <ProposalIcon />,
         count: deals?.proposalDeals.length,
      },
      {
         to: '/orders',
         label: t('orders'),
         icon: <OrderIcon />,
         count: deals?.orderDeals.length,
      },
      {
         to: '/messages',
         label: t('contact'),
         icon: <ChatsIcon />,
         count: conversations?.conversationThreads.length,
      },
      {
         to: '/claims',
         label: t('tickets'),
         icon: <ClaimIcon />,
         count: tickets?.claimTickets.length,
      },
   ];

   let logoUrl = `${process.env.PUBLIC_URL}/img/logos/${config.logo}`;
   if (config.logo.includes('http')) {
      logoUrl = config.logo;
   }

   const getDay = () => {
      const days = [
         'sunday',
         'monday',
         'tuesday',
         'wednesday',
         'thursday',
         'friday',
         'saturday',
      ];
      return days[new Date().getDay()];
   };

   return (
      <>
         <div className={styles.header}>
            <Link to='/'>
               <img src={logoUrl} alt={config.name} className={styles.logo} />
            </Link>

            <div className={styles.links}>
               <NavLink
                  className={styles.navitem}
                  to={`${config.storeUrl}/customer/account/index/`}
                  target='_blank'
               >
                  <UserIcon />
                  {t('profile')}
               </NavLink>
               <NavLink to={'/support'} className={styles.navitem}>
                  <SupportIcon />
                  {t('support')}
               </NavLink>
               <NavLink
                  className={styles.navitem}
                  to={`${config.storeUrl}/customer/account/logout/`}
                  target='_blank'
               >
                  <LogoutIcon />
                  {t('logout')}
               </NavLink>

               <button
                  className={`${styles.navitem} ${styles.burger}`}
                  type='button'
                  onClick={() => setOpen(!open)}
               >
                  {open ? <CloseIcon /> : <BurgerIcon />}
                  {t(open ? 'menu' : 'close')}
               </button>
            </div>
         </div>

         <div className={`${styles.sidebar} ${open ? styles.open : ''}`}>
            <nav className={styles.menu}>
               {links.map((link, index) => (
                  <NavLink
                     key={index}
                     to={link.to}
                     className={({ isActive }) =>
                        `${styles.link} ${isActive ? styles.active : ''}`
                     }
                  >
                     {link.icon}
                     {link.label}
                     {(link.count || 0) > 0 ? (
                        <span className={styles.count}>{link.count}</span>
                     ) : (
                        ''
                     )}
                  </NavLink>
               ))}
            </nav>

            <div className={styles.support}>
               {config.supportImage && (
                  <img
                     src={config.supportImage}
                     alt={config.name}
                     className={styles.supportImage}
                  />
               )}

               <strong>{t('contact_support')}</strong>

               {config.email && (
                  <a target='_blank' href={`mailto:${config.email}`}>
                     {config.email}
                  </a>
               )}
               {config.phone && (
                  <a target='_blank' href={`tel:${config.phone}`}>
                     {config.phone}
                  </a>
               )}

               <div className={styles.openingHours}>
                  <span className={styles.openNow} />
                  {t('phone_hours')}{' '}
                  {config.openingHours?.[getDay() as 'monday']}
               </div>
            </div>
         </div>
      </>
   );
}
