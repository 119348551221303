import { Helmet } from 'react-helmet';
import { BrandColor, SiteConfig } from '../types/User';

interface Props {
   config: SiteConfig | null;
}

export default function ConfigToHelmet({ config }: Props) {
   if (!config) {
      return null;
   }

   const getFavicon = (brand: string) => {
      // SPAR
      if (brand == 'sparwindows') return 'spar-favicon.png';
      if (brand == 'sparvinduer') return 'spar-favicon.png';
      if (brand == 'sparokna') return 'spar-favicon.png';
      if (brand == 'sparfenster') return 'spar-favicon.png';
      if (brand == 'sparkozijnen') return 'spar-favicon.png';
      if (brand == 'sparikkunat') return 'spar-favicon.png';
      if (brand == 'sparfönster') return 'spar-favicon.png';
      if (brand == 'sparevinduer') return 'spar-favicon.png';

      // JNA
      if (brand == 'jna') return 'jna-favicon.png';

      // BB Yellow
      if (brand == 'bedst & billigst') return 'bb-yellow-favicon.png';
      if (brand == 'best & billigst') return 'bb-yellow-favicon.png';

      // BB Red
      if (brand == 'bonusfönster') return 'bb-red-favicon.png';
   };

   const colors = config.colors;
   const styleString = `
      :root {
         ${
            colors
               ? Object.keys(colors)
                    .map((color) =>
                       colors[color as BrandColor]
                          ? ` --color-${color}: ${colors[color as BrandColor]}; \n`
                          : ''
                    )
                    .join('')
               : ''
         }
         ${config.fontName && `--font-base: '${config.fontName}';\n`}
      }`;

   return (
      <Helmet>
         <title>{config.name}</title>
         <style id='helmet'>{styleString}</style>
         {config.fontLink && <link href={config.fontLink} rel='stylesheet' />}
         <link
            rel='icon'
            type='image/png'
            href={`/img/favicons/${getFavicon(config.name.toLowerCase())}`}
         />
      </Helmet>
   );
}
