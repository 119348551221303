import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import { useContext, useEffect } from 'react';
import Loading from '../../components/Loading/Loading';
import styles from './MessagePage.module.scss';
import { formatDate } from '../../utils/formatDate';
import Button from '../../components/Button/Button';
import { ReactComponent as AttachmentIcon } from '../../assets/icons/attachment.svg';

export default function MessagePage() {
   const { t } = useTranslation('messages');

   let { id } = useParams();

   const { fetchConversationThreads, conversationThreads, conversations } =
      useContext(UserContext);

   useEffect(() => {
      if (!id) return;

      fetchConversationThreads(id);
   }, [id]);

   const messages = conversationThreads[id || ''];
   const conversation = conversations?.conversationThreads.find(
      (thread) => thread.id === id
   );

   if (!messages || !conversation) {
      return (
         <div className={styles.loading}>
            <Loading />
         </div>
      );
   }

   return (
      <div>
         <div className={styles.header}>
            <div>
               <div className={styles.subject}>{t('subject')}</div>
               {messages.length > 0 ? messages[0].subject : ''}
            </div>
            <Button
               className={styles.print}
               onClick={() => window.print()}
               small
               variant='ghost'
            >
               {t('print')}
            </Button>
         </div>

         <div className={styles.messages}>
            {messages.map((message) => (
               <div
                  key={message.id}
                  title={formatDate(message.createdAt, true)}
               >
                  {message.sentBy.length > 0 && (
                     <div
                        className={`${styles.from} ${
                           message.toFrom === 'sent_by_user'
                              ? styles.sent
                              : styles.received
                        }`}
                     >
                        <span>
                           {message.sentBy
                              .map((contact) => contact.email)
                              .join(', ')}{' '}
                           • {formatDate(message.createdAt, true)}
                        </span>
                     </div>
                  )}

                  <div
                     className={`${styles.message} ${
                        message.toFrom === 'sent_by_user'
                           ? styles.sent
                           : styles.received
                     }`}
                  >
                     <div
                        className={styles.body}
                        dangerouslySetInnerHTML={{ __html: message.richText }}
                     />

                     {message.attachments.length > 0 && (
                        <div>
                           {message.attachments.map((attachment) => (
                              <a
                                 key={attachment.url}
                                 href={attachment.url}
                                 className={styles.attachment}
                                 target='_blank'
                              >
                                 {attachment.type === 'image' ? (
                                    <img
                                       src={attachment.url}
                                       alt={attachment.name}
                                    />
                                 ) : (
                                    <AttachmentIcon />
                                 )}
                                 {attachment.name}
                              </a>
                           ))}
                        </div>
                     )}
                  </div>
               </div>
            ))}
         </div>
      </div>
   );
}
