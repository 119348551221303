export const claimStatusMap = {
   'support-new': 'support-new',
   'support-waiting-on-contact': 'support-waiting-on-contact',
   'support-waiting-on-us': 'support-waiting-on-us',
   'support-closed': 'support-closed',
   'technician-new': 'technician-new',
   'technician-customer-contacted-ready-for-driving':
      'technician-customer-contacted-ready-for-driving',
   'technician-case-closed': 'technician-case-closed',
   'technician-new-jylland': 'support-new',
   'technician-new-fyn': 'support-new',
   'technician-new-sjaelland': 'support-new',
   'technician-awaiting-customer': 'support-new',
   'technician-approved': 'support-new',
   'technician-new-time-wanted': 'support-new',
};
